import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import BizTracker from '~/common/tracker/bizTracker'
import { globalActionCreator } from '~/common/globalActions'
import { actionCreator } from '../../store'
import { withBasic } from '@common'
import Logger from '~/config/logger'

import loggerConfig from '../../logger.config'

import styles from './style.m.scss'
import { PhoneInputNew } from '~/views/login/components/newInputs/PhoneInputNew'
import { SwitchLoginType } from '~/views/login/components/SwitchLoginType'
import { LoginSubmitBtn } from '~/views/login/components/LoginSubmitBtn'
import { AgreementCheckBox } from '~/views/login/components/AgreementCheckBox'
import { PasswordInputNew } from '~/views/login/components/newInputs/PasswordInput'

@withBasic
class LoginForm extends PureComponent {
  constructor() {
    super()
    this.state = {
      phoneNumber: '',
      password: '',
      phoneStatus: false, //手机号码是否合法
      imageCaptcha: '', // 图形验证码
    }
    this.controls = []
    this.saLogger = Logger.getInstance()
  }

  handlePhoneInput = (status) => {
    this.setState({
      phoneStatus: status,
    })
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  submitPassword = () => {
    const { agreementSelected, translate, showGlobalToast } = this.props
    if (!agreementSelected) {
      showGlobalToast(translate('请勾选条款协议'))
      return
    }
    BizTracker.click({
      cn: 13,
      sn: 390001,
    })
    const {
      loginWithPwd,
      setPasswordErrorText,
      setPhoneNumberErrorText,
      curPeriod,
      callbackPageUrl,
      defaultPhoneNumber,
      needPwdLoginGraphicCode,
    } = this.props
    const { password, phoneNumber, imageCaptcha } = this.state

    const result = this.controls.every((elem) => {
      // if (elem.name !== 'password') return true //只校验密码
      const errMsg = elem.validate(
        elem.state.value === null ? elem.props.defaultValue : elem.state.value,
      )

      if (errMsg === false) {
        return true
      } else {
        if (elem.name === 'password') {
          setPasswordErrorText(errMsg)
        } else {
          setPhoneNumberErrorText(errMsg)
        }
        return false
      }
    })

    if (result) {
      const params = {
        phoneNumber: phoneNumber ? phoneNumber : defaultPhoneNumber,
        password,
        curPeriod,
        callbackPageUrl,
      }
      needPwdLoginGraphicCode && (params.imageCaptcha = imageCaptcha)
      loginWithPwd(params)
      this.saLogger.onPageClick({
        ...loggerConfig.login,
        Aku_channelName: 'account',
      })
    }
  }

  //如果控件被销毁，则要重置数组
  removeController = (name) => {
    if (!name) return

    this.controls = this.controls.filter((elem) => {
      if (elem.name !== name) return elem
    })
  }

  render() {
    const {
      translate,
      countryCode,
      setPasswordErrorText,
      setPhoneNumberErrorText,
      pwdErrorText,
      phoneNumberErrorText,
      setValidPhoneNumber,
      type,
      defaultPhoneNumber,
      onForget,
      canModifyPhoneNumber,
      activityInfo,
    } = this.props

    return (
      <form key="loginForm" className={styles.formLogin}>
        <PhoneInputNew
          className={styles.mb14}
          name="phoneNumber"
          placeholder={translate('手机号')}
          onRegister={this.register}
          onControl={this.collect}
          defaultValue={defaultPhoneNumber}
          validation={{ type: 'phone' }}
          onChange={(value) => {
            // 手机号码输入框
            BizTracker.input({
              key: 'password-login-phoneinput',
              cn: 9,
              ic: value,
              sn: 390001,
            })
          }}
          onSuccess={() => {
            setPhoneNumberErrorText('')
            setTimeout(() => {
              //取最新的phoneNumber 给忘记密码用的
              setValidPhoneNumber(this.state.phoneNumber)
            }, 0)
            this.handlePhoneInput(true)
          }}
          onError={(errMsg) => {
            setValidPhoneNumber('')
            setPhoneNumberErrorText(errMsg)
            this.handlePhoneInput(false)
          }}
          onFocus={() => {
            setPhoneNumberErrorText('')
            this.saLogger.onPageClick({
              ...loggerConfig.click_phone_num,
              Aku_channelName: 'account',
            })
          }}
          onBlur={() => {
            this.saLogger.onPageInput({
              ...loggerConfig.input_phone_num,
              Aku_channelName: 'account',
            })
          }}
          errorText={phoneNumberErrorText}
          countryCode={countryCode}
          required
          platform={type === 'pc' ? 'pc' : 'mobile'}
          hasClearIcon
          disabled={
            canModifyPhoneNumber !== undefined && canModifyPhoneNumber !== null
              ? !canModifyPhoneNumber
              : false
          }
        />
        <PasswordInputNew
          type="password"
          name="password"
          placeholder={translate('请输入密码')}
          onRegister={this.register}
          onControl={this.collect}
          onFocus={() => {
            setPasswordErrorText('')
            this.saLogger.onPageClick({
              ...loggerConfig.click_password_box,
              Aku_channelName: 'account',
            })
          }}
          onChange={(value) => {
            // 手机密码输入框
            BizTracker.input({
              key: 'password-login-passwordinput',
              cn: 10,
              ic: value,
              sn: 390001,
            })
          }}
          onBlur={() => {
            this.saLogger.onPageInput({
              ...loggerConfig.input_password,
              Aku_channelName: 'account',
            })
          }}
          onSuccess={() => {
            setPasswordErrorText('')
          }}
          errorText={pwdErrorText}
          required
          hasClearIcon
        />

        <SwitchLoginType>
          <span
            // className={styles.forget}
            className={'ml-auto'}
            onClick={() => {
              BizTracker.click({
                sn: 390001,
                cn: 11,
              })
              // 修改密码弹窗曝光
              BizTracker.expose({
                cn: 19,
                sn: 390001,
              })
              // this.saLogger.onPageClick(elementId.CLICK_FORGOT_PASSWORD)
              this.saLogger.onPageClick(loggerConfig.forget_password_popup)
              this.saLogger.onPageClick({
                ...loggerConfig.forget_password,
                Aku_channelName: 'account',
              })
              onForget && onForget()
            }}
          >
            {translate('忘记密码？')}
          </span>
        </SwitchLoginType>

        <LoginSubmitBtn
          onClick={this.submitPassword}
          disabled={false /* !codeStatus || !phoneStatus */}
          className={styles.mb14}
          activityInfo={activityInfo}
        ></LoginSubmitBtn>

        <AgreementCheckBox></AgreementCheckBox>
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  pwdErrorText: state.getIn(['login', 'pwdErrorText']),
  phoneNumberErrorText: state.getIn(['login', 'phoneNumberErrorText']),
  callbackPageUrl: state.getIn(['login', 'callbackPageUrl']),
  defaultPhoneNumber: state.getIn(['login', 'defaultPhoneNumber']),
  curPeriod: state.getIn(['login', 'curPeriod']),
  needPwdLoginGraphicCode: state.getIn(['login', 'needPwdLoginGraphicCode']),
  canModifyPhoneNumber: state.getIn(['main', 'canModifyPhoneNumber']),
  agreementSelected: state.getIn(['login', 'agreementSelected']),
  activityInfo: state.getIn(['login', 'homeInfo', 'activityInfo'])?.toJS(),
})

const mapDispatchToProps = (dispatch) => ({
  setValidPhoneNumber(validPhoneNumber) {
    dispatch(actionCreator.goSetCurValidPhoneNumber(validPhoneNumber))
  },
  setPasswordErrorText(text) {
    dispatch(actionCreator.goSetPasswordErrorText(text))
  },
  setPhoneNumberErrorText(text) {
    dispatch(actionCreator.goSetPhoneNumberErrorText(text))
  },
  loginWithPwd(params) {
    dispatch(actionCreator.goLoginWithPwd(params))
  },
  showGlobalToast(errMsg) {
    dispatch(globalActionCreator.toggleToast(true, errMsg))
  },
  setDefaultPhoneNumber(phoneNumber) {
    dispatch(actionCreator.goSetDefaultPhoneNumber(phoneNumber))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
