export const appPaymentGuideLoggerConfig = {
  // APP支付引导页
  pageView: {
    Aku_page_id: 'openpay17',
    Aku_page_name: 'app payment guide',
  },
  // 无法打开akulaku APP-弹窗曝光
  popView: {
    Aku_page_id: 'openpay17',
    Aku_page_name: 'app payment guide',
    Aku_pop_id: 'pop90006',
    Aku_pop_name: 'unable to open app',
  },
  // 返回商户
  return_openpay170101: {
    Aku_page_id: 'openpay17',
    Aku_page_name: 'app payment guide',
    Aku_element_id: 'openpay170101',
    Aku_element_name: 'return',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'guide',
    Aku_position_id: '01',
  },
  // 点击使用akulaku APP支付
  app_openpay170102: {
    Aku_page_id: 'openpay17',
    Aku_page_name: 'app payment guide',
    Aku_element_id: 'openpay170102',
    Aku_element_name: 'app',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'guide',
    Aku_position_id: '02',
  },
  // 点击使用浏览器支付
  browser_openpay170103: {
    Aku_page_id: 'openpay17',
    Aku_page_name: 'app payment guide',
    Aku_element_id: 'openpay170103',
    Aku_element_name: 'browser',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'guide',
    Aku_position_id: '03',
  },
  // 点击使用浏览器支付
  browser_pop9000601: {
    Aku_page_id: 'openpay17',
    Aku_page_name: 'app payment guide',
    Aku_element_id: 'pop9000601',
    Aku_element_name: 'browser',
    Aku_pop_id: 'pop90006',
    Aku_pop_name: 'unable to open app',
  },
}
