import React, { PureComponent } from 'react'

import { FormInput } from '~/components/FormInput'
import baseInputStyles from '../baseInput.scss'
import { classNames } from '@common/helpers'

export class PasswordInputNew extends PureComponent {
  state = {
    errMsg: '',
  }

  setInternalErrMsg = (errMsg) => {
    this.setState({
      errMsg,
    })
  }

  onInputError = (errMsg) => {
    const { onError } = this.props
    onError && onError(errMsg)

    this.setInternalErrMsg(errMsg)
  }

  onInputSuccess = () => {
    const { onSuccess } = this.props
    onSuccess && onSuccess()
    this.setInternalErrMsg('')
  }

  render() {
    const { errorText, className, ...others } = this.props
    const { errMsg } = this.state
    const showErrText = !!errorText || !!errMsg

    return (
      <div
        className={classNames(
          baseInputStyles.inputWrapper,
          !!showErrText && baseInputStyles.hasError,
          className,
        )}
      >
        <div className={baseInputStyles.inputContent}>
          <div className={baseInputStyles.FormInputWrapper}>
            <FormInput
              {...others}
              className={`font-roboto-medium`}
              hasTips={false}
              onError={this.onInputError}
              onSuccess={this.onInputSuccess}
              hasEye
            />
          </div>
        </div>

        <span className={baseInputStyles.errorText}>{errorText || errMsg}</span>
      </div>
    )
  }
}
