import PropTypes from 'prop-types'
import React, { PureComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  SLIDER_CONFIG,
  SCENARIO_NUMBER_MAP,
  PHONE_COUNTRY_CODE_MAP,
  FIRST_PAGE_LOGIN_TYPE,
  SECURITY_VERIFICATION_CALL_METHOD,
  SECURITY_VERIFICATION_BIZ_TYPE,
  FACE_CONFIG,
} from '~/common/macro'
import { actionCreator } from './store'
import { helpers, withBasic } from '@common'
import ScrollView from '@Arui/layout/ScrollView'
import { globalActionCreator } from '~/common/globalActions'
import { SecurityVerificationMobile as SecurityVerification } from '@/components/SecurityVerification'
import { getCommonParams } from '@/components/Security/utils'
import { getSmDeviceData, getDeviceUUID } from '@/components/device-sdk'
import checkRejectReason from '@/components/Security/checkRejectReason'
import styles from './style.m.scss'

import Logger from '../../config/logger'
import { Translate } from '../../config/translate'
import LoginForm from './components/LoginForm'
import { ForgetPassword } from '~/components/ForgetPassword'

const appId = helpers.URL.getParam('appId')

import BizTracker from '~/common/tracker/bizTracker'
import loggerConfig from './logger.config'
import { getApiBaseUrl, phoneDesensitization } from '~/config/utils'

import SMSLoginForm from './components/SMSLoginForm'
// import SetPasswordForm from './components/SetPasswordForm'
import { store } from '~/store'
import { Header } from '~/components/Header'
import { InstallmentPlan } from '~/components/installmentPlan'
import { Footer } from '~/components/Footer'
import { NoticeCard } from '~/components/NoticeCard'
import { GuideToAppOpen } from '~/views/login/components/GuideToAppOpen'
import { NotHaveInstallment } from '~/views/login/components/NotHaveInstallment'
import { urlQuery } from '~/common/urlQuery'
import { appPaymentGuideLoggerConfig } from '~/views/login/appPaymentGuide.logger'
import withPerfCollect from '@/components/withPerfCollect'
import { classNames } from '@/vendor/common @/helpers'

const { getRuntimeEnv } = helpers

function MainBodyTitle(props) {
  const { translate } = Translate.getInstance()
  let title =
    props.loginType === FIRST_PAGE_LOGIN_TYPE.SMS
      ? translate('短信登录')
      : translate('密码')

  return (
    <div className={`font-roboto-bold ${styles.mainBodyTitle}`}>{title}</div>
  )
}

MainBodyTitle.propTypes = {
  loginType: PropTypes.any.isRequired,
}

const SaPageView = (props) => {
  const isGuide = !!props.guide
  const saLogger = Logger.getInstance()
  useEffect(() => {
    if (isGuide) {
      // app 引导页
      saLogger.onPageView(appPaymentGuideLoggerConfig.pageView)
    } else {
      // 登录页
      getAndReportConfig()
    }
  }, [isGuide])

  const getAndReportConfig = async () => {
    const { data } = await props.dispatch(
      globalActionCreator.globalGetReportConfig(),
    )
    saLogger.onPageView({
      ...loggerConfig.page_view,
      refNo: props.refNo,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      partnerUserId: data?.partnerUserId,
      Aku_scene: data?.akuScene ? 1 : 2,
      appName: data?.appName,
    })
    BizTracker.enter({ sn: 390001 })
  }

  return null
}
SaPageView.propTypes = {
  guide: PropTypes.any,
}

@withBasic
class Login extends PureComponent {
  constructor(props) {
    super(props)
    this.isLoginTypeInited = false
    this.saLogger = Logger.getInstance()
    this.state = {
      benefitDesc: '',
      deviceUuid: helpers.storage.getSession('deviceData')?.uuid,
    }
  }

  render() {
    const {
      loginType,
      securityVerificationOnSuccess,
      securityVerificationOnRejected,
      securityVerificationOnClose,
    } = this.props
    const {
      languageCode,
      securityVerificationVisible,
      validPhoneNumber,
      defaultPhoneNumber,
      countryId,
      installmentList,
      history,
    } = this.props

    /**
     * @typedef {object} QueryType
     * @property {string} guide 是否引导到app打开
     */
    /**
     * @type {QueryType}
     */
    const query = urlQuery.parse(this.props.location.search) || {}

    /**
     * 是否有分期试算
     * Alipay+ 场景无分期试算
     */
    const hasInstallmentList = !!installmentList?.length
    const { translate } = Translate.getInstance()

    return (
      <ScrollView className={styles.container}>
        <SaPageView
          guide={query.guide}
          dispatch={this.props.dispatch}
          refNo={this.props.refNo}
        ></SaPageView>
        <Header rightText={this.props.adTagContent}></Header>

        <div className={styles.containerBody}>
          <NoticeCard>{this.props.loginHeadText}</NoticeCard>

          {hasInstallmentList && (
            <InstallmentPlan
              curPeriod={this.props.curPeriod}
              list={this.props.installmentList}
              activityInfo={this.props.activityInfo}
              showPromotionAmount={false}
              onCurPeriodChange={(period) => {
                BizTracker.click({
                  cn: 1,
                  sn: 390001,
                  ext: {
                    // order_amount：订单金额
                    // installment_terms:分期方案
                    order_amount: this.props.payAmount,
                    installment_terms: period,
                  },
                })
                this.props.dispatch(actionCreator.goChooseCurPeriod(period))
              }}
              payAmount={this.props.payAmount}
              merchantName={this.props.merchantName}
              tips={this.props.installmentEndText}
              onExpandChange={(expand) => {
                const saLogger = Logger.getInstance()
                saLogger.onPageClick(
                  expand
                    ? loggerConfig.expand_openpay010116
                    : loggerConfig.collapse_openpay010117,
                )
              }}
              renderAfterTips={() => {
                if (query.guide) {
                  return (
                    <GuideToAppOpen
                      className={'pt-28pt'}
                      discount={this.props.promotionText}
                    ></GuideToAppOpen>
                  )
                }
                return null
              }}
            ></InstallmentPlan>
          )}

          {query.guide ? (
            hasInstallmentList ? null : (
              <NotHaveInstallment
                images={this.props.bannerImages?.toJS()}
                discount={this.props.promotionText}
              ></NotHaveInstallment>
            )
          ) : (
            <div className={styles.mainBody}>
              <MainBodyTitle loginType={loginType}></MainBodyTitle>

              {loginType === FIRST_PAGE_LOGIN_TYPE.ACCOUNT ? (
                <LoginForm onForget={this.props.forgetPassword} />
              ) : (
                <SMSLoginForm />
              )}
              <div
                className={classNames(styles.registerBanner, 'font-regular')}
              >
                <div className={styles.registerIcon}>
                  <img src={require('../set-password/images/tips.png')} />
                </div>
                <span className="font-regular">{this.state.benefitDesc}</span>
                <div
                  className={classNames('font-regular', styles.registerBtn)}
                  onClick={() =>
                    history.push({
                      pathname: `/register`,
                      search: `${window.location.search}`,
                    })
                  }
                >
                  {translate('注册')}
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer></Footer>
        {securityVerificationVisible ? (
          <SecurityVerification
            hideInitialLoading={true}
            visible={securityVerificationVisible}
            locale={languageCode ? languageCode.toLowerCase() : 'in'}
            languageCode={languageCode ? languageCode.toLowerCase() : 'in'}
            env={getRuntimeEnv()}
            deviceId={this.props.device_id}
            deviceUuid={this.state.deviceUuid}
            bizType={
              loginType === FIRST_PAGE_LOGIN_TYPE.ACCOUNT
                ? SECURITY_VERIFICATION_BIZ_TYPE.LOGIN
                : SECURITY_VERIFICATION_BIZ_TYPE.REGISTER
            }
            extraParams={{
              phone: validPhoneNumber || defaultPhoneNumber,
              platformId: appId,
              partnerPhone: validPhoneNumber || defaultPhoneNumber,
              ...getCommonParams(),
            }}
            saParams={{
              pageId: 'openpay01',
              pageName: 'opaypay-login page',
            }}
            countryCode={`+${PHONE_COUNTRY_CODE_MAP[countryId]}`}
            phoneNumber={phoneDesensitization(
              validPhoneNumber || defaultPhoneNumber,
            )}
            onSuccess={securityVerificationOnSuccess}
            onRejected={(retry, extra) => {
              securityVerificationOnRejected(
                retry,
                extra,
                validPhoneNumber || defaultPhoneNumber,
                loginType === FIRST_PAGE_LOGIN_TYPE.ACCOUNT
                  ? SECURITY_VERIFICATION_BIZ_TYPE.LOGIN
                  : SECURITY_VERIFICATION_BIZ_TYPE.REGISTER,
              )
            }}
            onClose={securityVerificationOnClose}
            // onRequestError={securityVerificationOnClose}
            apiBaseURL={getApiBaseUrl('/capi')}
            getSmDeviceData={getSmDeviceData}
            sliderData={SLIDER_CONFIG}
            faceOptions={FACE_CONFIG}
          />
        ) : null}

        {/* <SetPasswordForm /> 改为页面*/}
      </ScrollView>
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showToast } = this.props

    if (nextProps.needLogoutToast) {
      showToast()
    }

    if (nextProps.passwordLoginFirstAppIdList && !this.isLoginTypeInited) {
      // 在配置中心配置了灰度的渠道，优先展示账密登录
      if (nextProps.passwordLoginFirstAppIdList.includes(appId)) {
        this.props.dispatch(
          actionCreator.goSetLoginType(FIRST_PAGE_LOGIN_TYPE.ACCOUNT),
        )
      }
      this.isLoginTypeInited = true
    }
  }

  componentDidMount() {
    const {
      appId,
      refNo,
      sign,
      getInstallmentInfo,
      defaultPeriod,
      clearOrderDetailData,
      uploadDeviceData,
    } = this.props

    clearOrderDetailData()
    uploadDeviceData()
    // 获取首页的分期新
    getInstallmentInfo(appId, refNo, sign, defaultPeriod)
    this.getRegisterBenefitDesc()
    this.props.dispatch(actionCreator.getHomeInfo())
    if (!this.state.deviceUuid) {
      getDeviceUUID().then((uuid) => {
        this.setState({ deviceUuid: uuid })
      })
    }
  }

  componentWillUnmount() {
    BizTracker.leave()
  }

  getRegisterBenefitDesc = async () => {
    const { data } = await this.props.getRegisterBenefitDesc()
    this.setState({
      benefitDesc: data?.benefitDesc,
    })
  }
}

const mapStateToProps = (state) => {
  let installmentList = state.getIn(['login', 'installmentList'])
  installmentList = Array.isArray(installmentList)
    ? installmentList
    : installmentList?.toJS()
  return {
    device_id: helpers.storage.get('deviceId') || 'unknown',
    appId: state.getIn(['main', 'appId']),
    refNo: state.getIn(['main', 'refNo']),
    sign: state.getIn(['main', 'sign']),
    defaultPeriod: state.getIn(['main', 'defaultPeriod']),
    curPeriod: state.getIn(['login', 'curPeriod']),
    callbackPageUrl: state.getIn(['login', 'callbackPageUrl']),
    needLogoutToast: state.getIn(['login', 'needLogoutToast']),
    loginType: state.getIn(['login', 'loginType']),
    installmentList,
    validPhoneNumber: state.getIn(['login', 'validPhoneNumber']),
    defaultPhoneNumber: state.getIn(['login', 'defaultPhoneNumber']),
    securityVerificationVisible: state.getIn([
      'login',
      'securityVerificationVisible',
    ]),
    payAmount: state.getIn(['login', 'payAmount']),
    merchantName: state.getIn(['login', 'merchantName']),
    adTagContent: state.getIn(['login', 'adTagContent']),
    loginHeadText: state.getIn(['login', 'homeInfo', 'loginHeadText']),
    bannerImages: state.getIn(['login', 'homeInfo', 'bannerImages']),
    promotionText: state.getIn(['login', 'homeInfo', 'promotionText']),
    installmentEndText: state.getIn([
      'login',
      'homeInfo',
      'installmentEndText',
    ]),
    passwordLoginFirstAppIdList: state.getIn([
      'main',
      'config',
      'passwordLoginFirstAppIdList',
    ]),
    activityInfo: state.getIn(['login', 'homeInfo', 'activityInfo'])?.toJS(),
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getRegisterBenefitDesc() {
    return dispatch(actionCreator.goGetRegisterBenefitDesc())
  },
  uploadDeviceData() {
    dispatch(
      globalActionCreator.globalSendDeviceData({
        scenario_number: SCENARIO_NUMBER_MAP.LOGIN_PAGE,
      }),
    )
  },

  forgetPassword() {
    const translate = Translate.getInstance().translate
    // dispatch(actionCreator.goSetForgetPasswordSMS())

    dispatch(
      globalActionCreator.toggleAlertDialog(true, {
        title: translate('如何找回密码?'),
        content: <ForgetPassword translate={translate} />,
        footer: [],
        hideFooter: true,
      }),
    )
  },
  getInstallmentInfo(appId, refNo, sign, defaultPeriod) {
    dispatch(
      actionCreator.goGetInstallmentInfo(appId, refNo, sign, defaultPeriod),
    )
  },
  clearOrderDetailData() {
    dispatch(globalActionCreator.globalClearOrderDetailData())
  },
  showToast() {
    const translate = Translate.getInstance().translate
    dispatch(
      globalActionCreator.toggleToast(
        true,
        translate('您的账号已在新设备登录，如果账号异常，请重新修改密码'),
      ),
    )
    dispatch(actionCreator.goSetNeedLogoutToast(false))
  },

  /**
   * 安全组件校验成功
   * - 登录的话 继续走登录过程
   * - 验证码的话 发起验证码
   * */
  securityVerificationOnSuccess({ operationId }) {
    const state = store.getState()
    const saLogger = Logger.getInstance()
    const securityVerificationCaller = state.getIn([
      'login',
      'securityVerificationCaller',
    ])
    const securityVerificationTempParams = state
      .getIn(['login', 'securityVerificationTempParams'])
      .toJS()

    dispatch(actionCreator.toggleSecurityVerification(false))
    // 如果前置调起方是登录操作，就继续登录操作，如果是获取验证码，那就继续获取验证码 其他情况调起的就是异常
    if (
      securityVerificationCaller === SECURITY_VERIFICATION_CALL_METHOD.LOGIN
    ) {
      saLogger.click({
        ...loggerConfig.login_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.LOGIN,
        return_code: 1,
      })
      dispatch(actionCreator.setOperationId(operationId))
      dispatch(
        actionCreator.goLoginWithPwd({
          ...securityVerificationTempParams,
          securityCheck: true,
        }),
      )
    } else if (
      securityVerificationCaller === SECURITY_VERIFICATION_CALL_METHOD.CAPTCHA
    ) {
      saLogger.click({
        ...loggerConfig.otp_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.REGISTER,
        return_code: 1,
      })
      dispatch(actionCreator.setOperationId(operationId))
      dispatch(
        actionCreator.goSendCodeWithPhoneNumber({
          ...securityVerificationTempParams,
          securityCheck: true,
        }),
      )
    } else {
      console.log('异常状态')
    }
  },
  // 安全组件关闭
  securityVerificationOnClose() {
    dispatch(actionCreator.toggleSecurityVerification(false))
  },
  // 安全组件拒绝
  securityVerificationOnRejected(retry, extra, phoneNumber, bizType) {
    dispatch(globalActionCreator.toggleLoading(false))
    const shouldClose = checkRejectReason(phoneNumber, bizType, extra)
    if (shouldClose) {
      dispatch(actionCreator.toggleSecurityVerification(false))
    }
    const state = store.getState()
    const saLogger = Logger.getInstance()
    const securityVerificationCaller = state.getIn([
      'login',
      'securityVerificationCaller',
    ])
    // 如果前置调起方是登录
    if (
      securityVerificationCaller === SECURITY_VERIFICATION_CALL_METHOD.LOGIN
    ) {
      saLogger.click({
        ...loggerConfig.login_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.LOGIN,
        return_code: 2,
      })
    } else {
      saLogger.click({
        ...loggerConfig.otp_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.REGISTER,
        return_code: 2,
      })
    }
  },
})

export default withPerfCollect()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)),
)
