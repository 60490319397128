import { Carousel } from 'antd-mobile'
import { FC } from 'react'
import { GuideToAppOpen } from '~/views/login/components/GuideToAppOpen'

interface Props {
  images?: string[]
  discount?: string
}

/**
 * 没有分期预算时展示
 */
export const NotHaveInstallment: FC<Props> = (props) => {
  const images = props.images

  return (
    <div
      className={`bg-white rounded-8pt flex flex-col items-center overflow-hidden`}
    >
      {!!images?.length && (
        <Carousel
          // autoplay
          infinite
          className={'h-314pt'}
          dotStyle={{ display: 'none' }}
          dotActiveStyle={{ display: 'none' }}
        >
          {images?.map((val) => (
            <img key={val} src={val} alt="" className={`w-full h-314pt`} />
          ))}
        </Carousel>
      )}

      <GuideToAppOpen
        className={'w-full px-16pt pt-22pt pb-12pt'}
        discount={props.discount}
      ></GuideToAppOpen>
    </div>
  )
}
