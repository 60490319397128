import { create, useModal, show, hide } from '@ebay/nice-modal-react'
import { useMount } from 'ahooks'
import { Modal } from 'antd-mobile'
import { ModalTitlePopNew } from '~/components/ModalTitlePopNew'
import Logger from '~/config/logger'
import { Translate } from '~/config/translate'
import { appPaymentGuideLoggerConfig } from '~/views/login/appPaymentGuide.logger'
import { payInBrowser } from '~/views/login/shared/payInBrowser'

const CannotOpenAkuAppPopupComponent = create(function CannotOpenAkuAppPopup() {
  const modal = useModal()
  const { translate } = Translate.getInstance()
  const saLogger = Logger.getInstance()
  useMount(() => {
    saLogger.popView(appPaymentGuideLoggerConfig.popView)
  })
  return (
    <Modal
      popup
      visible={modal.visible}
      onClose={modal.hide}
      animationType="slide-up"
      maskClosable={false}
      key={'popup'}
      onAnimationEnd={modal.remove}
    >
      <ModalTitlePopNew onClose={modal.hide}>
        {translate(`无法打开Akulaku APP？`)}
      </ModalTitlePopNew>

      <div className={`px-24pt pb-27pt`}>
        <div
          className={
            'text-gray-700 text-14pt leading-16ptflex flex-col text-left pb-47pt'
          }
        >
          <div className={'mb-14pt'}>
            {translate(
              `没有安装Akulaku APP您可以前往Google paly/App Store下载Akulaku APP并再次选择Akulaku APP支付`,
            )}
          </div>

          <div>
            {translate(
              `已安装Akulaku APP仍无法打开Akulaku APP支付，可能是当前商户APP不支持该功能，您可以选择使用浏览器支付`,
            )}
          </div>
        </div>

        <div
          className={'aku-btn-primary'}
          onClick={() => {
            modal.hide()
            saLogger.popClick(appPaymentGuideLoggerConfig.browser_pop9000601)
            payInBrowser()
          }}
        >
          {translate(`使用浏览器支付`)}
        </div>
      </div>
    </Modal>
  )
})
const showCannotOpenAkuAppPopupComponent = () => {
  return show(CannotOpenAkuAppPopupComponent)
}
const hideCannotOpenAkuAppPopupComponent = () => {
  return hide(CannotOpenAkuAppPopupComponent)
}

type CannotOpenAkuAppPopupType = typeof CannotOpenAkuAppPopupComponent & {
  show: typeof showCannotOpenAkuAppPopupComponent
  hide: typeof hideCannotOpenAkuAppPopupComponent
}

export const CannotOpenAkuAppPopup =
  CannotOpenAkuAppPopupComponent as CannotOpenAkuAppPopupType
CannotOpenAkuAppPopup.show = showCannotOpenAkuAppPopupComponent
CannotOpenAkuAppPopup.hide = hideCannotOpenAkuAppPopupComponent
