import _ from 'lodash'
import { history } from 'umi'
import { urlQuery } from '~/common/urlQuery'

/**
 * 在浏览器进行支付流程，去除url上的app引导标识
 */
export function payInBrowser() {
  const query = urlQuery.parse(history.location.search)
  history.push({
    pathname: history.location.pathname,
    search: urlQuery.stringify(_.omit(query, ['guide'])),
  })
}
