import { FC } from 'react'
interface Props {
  onClose?: AnyFunction
  /**
   * @default true
   */
  closeable?: boolean
}

/**
 * 给 新 UI 的弹窗添加标题
 */
export const ModalTitlePopNew: FC<Props> = (props) => {
  const { closeable = true, children, onClose } = props
  return (
    <div className={'relative pt-14pt flex justify-center pb-20pt'}>
      <div className={`font-roboto-medium text-#282b2e text-16pt leading-23pt`}>
        {children}
      </div>
      {closeable && (
        <img
          className={'absolute right-12pt top-14pt w-25pt h-25pt'}
          onClick={onClose}
          src={require('./actionsheet_close.png')}
          alt=""
        />
      )}
    </div>
  )
}
