import { useOpenApp } from '@/hooks/useOpenApp'
import { useRouterQuery } from '@/hooks/useRouterQuery'
import { isAndroid } from '@common/helpers'
import { useUnmount, useMount } from 'ahooks'
import { FC } from 'react'
import Logger from '~/config/logger'
import { Translate } from '~/config/translate'
import { appPaymentGuideLoggerConfig } from '~/views/login/appPaymentGuide.logger'
import { CannotOpenAkuAppPopup } from '~/views/login/components/CannotOpenAkuAppPopup'
import { payInBrowser } from '~/views/login/shared/payInBrowser'
import discountBg from './discount-bg.png'

interface Props {
  className?: string
  discount?: string
}

export const GuideToAppOpen: FC<Props> = (props) => {
  const { discount } = props
  const { translate } = Translate.getInstance()

  const { query } = useRouterQuery<{ actionKey: string; codeValue: string }>()

  const { openApp } = useOpenApp()

  const saLogger = Logger.getInstance()

  useMount(() => {
    const isInApplinkDomain = location.host.includes('applink')
    /**
     * 1. ios不显示引导页，原因是如果打开的是低版本iosapp，将不会跳转到op的页面，导致支付流程中断，所以在ios的情况下直接跳转到登录界面
     * 2. 在applink域名下需要跳过引导页（当前项目会部署在多个域名：applink.akulaku.com/ec-openpay.akulaku.com）
     */
    if (!isAndroid() || isInApplinkDomain) {
      payInBrowser()
    }
  })
  useUnmount(() => {
    CannotOpenAkuAppPopup.hide()
  })

  return (
    <div className={props.className}>
      <div
        className={`aku-btn-primary relative`}
        onClick={() => {
          saLogger.onPageClick(appPaymentGuideLoggerConfig.app_openpay170102)
          openApp({
            query,
            onTimeOut() {
              CannotOpenAkuAppPopup.show()
            },
          })
        }}
      >
        {translate('使用Akulaku APP支付')}
        {!!discount && (
          <div
            className={`font-roboto-medium absolute -top-15px right-0 h-36pt bg-no-repeat`}
            style={{
              backgroundImage: `url(${discountBg})`,
              backgroundSize: '100% 100%',
            }}
          >
            <div
              className={'h-22pt flex justify-center items-center min-w-95pt'}
            >
              <div className={'text-#e55b2c text-12pt leading-none pt-0.5pt'}>
                {discount}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={
          'w-full font-roboto-medium text-center leading-16pt text-14pt text-gray-700 decoration-underline py-12pt'
        }
        onClick={() => {
          saLogger.onPageClick(
            appPaymentGuideLoggerConfig.browser_openpay170103,
          )
          payInBrowser()
        }}
      >
        {translate('使用浏览器支付')}
      </div>
    </div>
  )
}
